.RoomCard {
  z-index: 5;
  width: 300px;
  text-align: left;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.08);

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.leftNav::-webkit-scrollbar {
  display: none;
}

.cardNav {
  position: absolute;
  top: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 1008px) {
  .RoomCard {
    overflow-y: scroll;
    max-height: calc(100vh - 40px);
  }
}

.roomCardInside {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}

.sharingTop {
  padding-top: 19px;
  border-top: 1px solid #D9D9D9;
}

.sharing {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 19px;
  color: #0072CE;
  border-top: 1px solid #D9D9D9;
}

.socialLink {
  display: flex;
  width: auto;
  max-height: 14px;
  margin-right: 5px;
  margin-left: 5px;
}

.socialIcon {
  width: auto;
  height: 14px;
  pointer-events: none;
}

.RoomCard hr {
  width: 100%;
  border-color: #D9D9D9;
}

.CTAContainer {
  padding-bottom: 19px;
}

.learnhref {
  padding-bottom: 19px;
  text-decoration: none;
  background-color: #0072CE;
}

.learnmore {
  padding: 11px 0;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
  color: #FFFFFF;
  background-color: #0072CE;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

span {
  margin-right: 10px;
}

.titleContainer {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #D9D9D9;
}

.overviewContainer {
  margin-bottom: 15px;
}

.overviewTitle,
.bodyTextTitle {
  margin-bottom: 10px;
  letter-spacing: -0.2px;
  color: #1E3575;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-right: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  border-radius: 50%;
}

.circle img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 12px;
  height: auto;
  margin: auto;
}

.titleText {
  letter-spacing: -0.2px;
  color: #1E3575;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.savingSubtitle {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #1E3575;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
}

.secImg {
  width: 100%;
  max-width: 260px;
}

.bodyContent {
  padding: 20px 0;
}

.bodyContent > img {
  margin-bottom: 10px;
}

.bodyText,
.text,
.roomCardInside {
  margin-bottom: 10px;
  letter-spacing: 0;
  color: #505357;
  font-size: 13px;
  line-height: 18px;
}

.text:last-of-type {
  margin-bottom: 0;
}

.bodyTextContainer {
  padding-bottom: 10px;
}

.bodyTextContainer:first-of-type {
  padding-bottom: 0;
}

.bodyTextContainer:last-of-type {
  padding-bottom: 0;
}

.bodyContent > ul,
.bodyContent > ol,
.tipContentContainer ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
}

.bodyContent > ul:last-child,
.tipContentContainer ul:last-child {
  margin-bottom: 0;
}

.bodyContent > ul li::before,
.tipContentContainer ul li:before {
  display: inline-block;
  padding-right: 20px;
  content: '\2022';
  color: #2E9CFF;
  font-weight: bold;
}

.bodyContent > ul li,
.tipContentContainer ul li {
  display: inline-flex;
  margin-bottom: 10px;
}

.bodyContent > ul li:last-of-type,
.tipContentContainer ul li:last-of-type {
  margin-bottom: 0;
}

.textlink,
.tipContentContainer a,
.overviewContainer a {
  text-decoration: none;
  color: #0072CE;
  border-bottom: 1px solid #0072CE;
  font-weight: bold;
}

.progressouter {
  min-width: 260px;
  max-width: 260px;
  border-radius: 3px;
  background-color: #999999;
}

.progressinner {
  height: 6px;
  border-radius: 3px;
  background-color: white;
}

.savingContainer {
  border-bottom: 1px solid #D9D9D9;
}

.savings {
  display: flex;
  align-items: center;
  padding: 13px 0;
}

.savingPrefix {
  margin-left: 3px;
}

.saveamount {
  margin-left: 6px;
  letter-spacing: 0.15px;
  color: #0072CE;
  font-size: 28px;
  font-weight: bold;
  line-height: 38px;
}

.saveunits {
  margin-left: 6px;
  letter-spacing: 0;
  color: #505357;
  font-size: 13px;
  line-height: 18px;
}

.slick-slide > div > div {
  outline: none;
}

.tipcard {
  display: flex;
  flex-direction: column;
  margin: 8px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  border-radius: 4px;
  background-color: #FCFCFC;
  box-shadow: 0 2px 8px 0 rgba(30, 53, 117, 0.2);

  -khtml-user-select: none; /* Konqueror HTML */
  -webkit-touch-callout: none; /* iOS Safari */
}

.tipContentContainer {
  padding: 14px;
  outline: none;
}

.tiptitle {
  margin-bottom: 14px;
  letter-spacing: -0.2px;
  color: #1E3575;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.tiptext {
  letter-spacing: 0;
  color: #505357;
  font-size: 13px;
  line-height: 18px;
}

.slick-slide .imgContainer {
  width: 100%;
}

.slick-slide img {
  width: 100%;
}

.slick-dots {
  position: relative !important;
  bottom: 0;
}

.slick-dots li button:before {
  opacity: 0.3 !important;
  color: #4379BB !important;
  font-size: 10px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #4379BB !important;
}

.carousel {
  padding-bottom: 14px;
}

.slick-dots {
  margin-top: 15px;
}

@media only screen and (max-width: 1008px) {
  .RoomCard {
    overflow-y: scroll;
    width: 100vw;
    height: 50vh;
    border-radius: 0;
  }

  .roomCardInside {
    margin-bottom: 0;
  }
}

.slick-dots li {
  margin: 0 0;
}

.disclosureText {
  padding-top: 20px;
  padding-bottom: 5px;
  letter-spacing: 0;
  color: #505357;
  font-size: 10px;
  line-height: 18px;
}

@media only screen and (max-width: 1008px) {
  .disclosureText {
    padding-bottom: 20px;
  }
}

/* desktop style */
@media only screen and (min-width: 1008px) {
  .cardNav {
    right: -320px;
  }

  .showCard {
    right: 20px;
  }
}

/* mobile style */
@media only screen and (max-width: 1008px) {
  .cardNav {
    bottom: -250px;
  }

  .showCard {
    bottom: 0;
  }
}
