@font-face {
  font-family: OpenSans-ExtraBold;

  src: url(/fonts/Open_Sans/OpenSans-ExtraBold.ttf);
}

@font-face {
  font-family: OpenSans-Bold;

  src: url(/fonts/Open_Sans/OpenSans-Bold.ttf);
}

.MapPage {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #FFFFFF;
  background-image: url('img/mapBG.jpg');
  background-position: center;
  background-size: cover;
}

.mapPageOverlay {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  pointer-events: none;
  background-image: url('img/mapBG.jpg');
  background-position: center;
  background-size: cover;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.lottieContainer {
  z-index: 5;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-left: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-align: center;
}


@media only screen and (max-width: 1008px) {
  .lottieContainer {
    min-height: auto;
  }
}

.lottieSideBarPadding {
  padding-left: 230px;
}

@media only screen and (max-width: 1008px) {
  .lottieSideBarPadding {
    padding-left: 0;
  }

  .lottieAnimations {
    pointer-events: none;
  }
}

.preventLottieInteraction {
  pointer-events: none;
}

.lottieAnimation {
  position: relative;
  width: 100%;
  max-width: 80%;
  height: auto;
  max-height: 70%;
  padding-top: 4%;
}


@media only screen and (max-width: 1008px) {
  .lottieAnimation {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 2%;
    display: flex;
    align-items: center;
    max-width: 95%;
    max-height: initial;
    margin: auto;
  }
}

.textArea {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: 450px;
  padding: 30px;
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  text-align: left;
}

.hideTextArea {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none;
  pointer-events: none;
  opacity: 0;
}

@media only screen and (max-width: 1008px) {
  .textArea {
    position: relative;
    width: calc(100% - 40px);
    padding: 20px;
    text-align: left;
    pointer-events: none;
  }
}

.textAreaMobile {
  position: relative;
  width: calc(100% - 40px);
  padding: 20px;
  text-align: left;
}


.presents {
  color: #1E3575;
  font-size: 15px;
}

.presentsStrong {
  font-family: 'OpenSans-ExtraBold';
}

.title {
  padding-top: 12px;
  color: #1E3575;
}

.titleFirstLine {
  color: #1E3575;
  font-size: 40px;
}

.titleSecondLine {
  margin-top: -10px;
  color: #1E3575;
  font-family: 'OpenSans-Bold';
  font-size: 40px;
}

.subTitle {
  color: #505357;
  font-size: 15px;
}

.social {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-top: 30px;
  white-space: nowrap;
  color: #0071CE;
}

@media only screen and (max-width: 1008px) {
  .title {
    padding-top: 5px;
  }

  .titleFirstLine,
  .titleSecondLine {
    font-size: 30px;
  }
  .subTitle {
    font-size: 12px;
  }

  .landingHorizontalLine {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .social {
    padding-top: 10px;
  }
}

.icon {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.startButton {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 115px;
  height: 115px;
  margin: auto;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  animation: pulse-animation 2s infinite;
  pointer-events: none;
  opacity: 0;
  border: none;
  border-radius: 50%;
  outline: none;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px 0 #FFFFFF1A;
}

.startBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.startButton:hover .startBackground {
  transform: scale(1.08);
}

.startButtonActive {
  pointer-events: all;
  opacity: 1;
}

.startButtonPulseRing2 {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: pulse-animation2 2s infinite;
  border-radius: 50%;
}

@media only screen and (max-width: 1008px) {
  .startButton {
    top: 25%;
  }
}

.startButtonText {
  z-index: 1;
  width: 60px;
  margin: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  text-align: center;
  letter-spacing: -0.27px;
  color: #0072CE;
  font-family: OpenSansBold;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
}

@keyframes pulse-animation2 {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
}

.roomHoverFade {
  position: absolute;
  top: 0;
  width: 220px;
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  opacity: 0;
}

.roomHoverContent {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  pointer-events: none;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(30,53,117,0.15);
}

.active {
  opacity: 1;
}

.roomHoverContentContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 7px 9.5px;
}

.roomHoverCurrentRoom {
  padding-right: 9px;
  text-align: center;
  letter-spacing: 0;
  color: #1E3575;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.roomHoverCompletion {
  padding: 4px 8px;
  text-align: center;
  letter-spacing: -0.15px;
  color: #FFFFFF;
  border-radius: 15px;
  background-color: #1E3575;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
}

.landingHorizontalLine {
  width: 95%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #D9D9D9;
}

.exploreTextContainer {
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 230px;
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  opacity: 0;
}

@media only screen and (max-width: 1008px) {
  .exploreTextContainer {
    padding-left: 0;
  }
}

.exploreTitle {
  height: fit-content;
  padding-bottom: 3px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #1E3575;
  font-family: OpenSansSemiBold;
  font-size: 18px;
  line-height: 24px;
}

.exploreTitleBelow {
  letter-spacing: 0;
  color: #505357;
  font-family: OpenSansLight;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}

.visible {
  opacity: 1;
}

.mobileFooter {
  position: fixed;
  left: 0;
  width: 100%;
  height: 124px;
  background-color: white;
}

.viewRoomButton {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  color: white;
  background-color: #0072CE;
  font-size: 14px;
}

.displayArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;
  padding-right: 30px;
  padding-left: 30px;
}

.mobileDisplayName {
  padding-right: 10px;
  color: #1E3575;
  font-family: 'OpenSans-Bold';
}

.mobilePercentage {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 97px;
  height: 25px;
  color: #FFFFFF;
  border-radius: 15px;
  background-color: #1E3575;
  font-size: 12px;
}

.displayText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.highlight,
#floor_floor1,
#floor_floor2,
#floor_attic,
#floor_basement,
#shadow_floor1,
#shadow_floor2,
#shadow_attic,
#shadow_basement {
  pointer-events: none;
}

.marker_market,
.marker_offers,
.marker_tips,
.marker_smart {
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  pointer-events: none;
}

.hitbox {
  opacity: 0;
}

.highlight {
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  pointer-events: none;
  opacity: 0;
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0.5!important;
}

/* Browser NOT SUPPORTED CSS */
.containerTop {
  display: flex;
  min-height: 100vh;
  background-color: #FFFFFF;
  background-image: url('img/mapBG.jpg');
  background-position: center;
  background-size: cover;
}

.containerContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  width: calc(100% - 106px);
  max-width: 960px;
  margin: auto;
  padding: 53px 175px;
}

.nsInto {
  max-width: 357px;
  text-align: left;
}

.nsTitle {
  padding-top: 10px;
  padding-bottom: 20px;
  letter-spacing: 0.17px;
  color: #2B2B2B;
  font-size: 32px;
  line-height: 44px;
}

.nsBody {
  letter-spacing: 0.09px;
  color: #2B2B2B;
  font-size: 16px;
  line-height: 22px;
}

.nsHouse {
  width: 100%;
  max-width: 580px;
}


.nsButton {
  margin-top: 30px;
  padding: 7px 40px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.1px;
  color: #FFFFFF;
  background-color: #0072CE;
  font-size: 18px;
  line-height: 25px;
}

.nsInto a {
  text-decoration: none;
}

@media only screen and (max-width: 1008px) {
  .containerContent {
    width: calc(100% - 40px);
    padding: 25px 20px;
  }

  .nsHouse {
    width: calc(100% - 40px);
    padding-top: 30px;
  }
}

.MapPage .videoWrapper {
  max-width: 300px;
}

.MapPage .videoConceal {
  opacity: 0;
  visibility: hidden;
}

.MapPage .videoReveal {
  opacity: 0;
  visibility: hidden;
  /*The values up top will be overriden by the animation keyframe*/
  /*The values for the duration and delay is roughly the same when the 3D map is fully loaded*/
  animation-name: fadeInWithMap;
  animation-duration: 1s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

.MapPage .videoPosition {
  position: absolute;
  bottom: 5%;
  z-index: 100;
  margin-left: 250px;
}

.MapPage .videoPositionMobile {
  position: absolute;
  left: 0; right: 0;
  top: 90px;
  z-index: 10;
  margin: 0 auto;
}

@keyframes fadeInWithMap {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}