.MiniMap {
  overflow: hidden;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  border: 0 solid white;
  border-radius: 50%;
  background-image: url('/assets/minimap/minimapbackground.svg');
  background-repeat: no-repeat;
  /* the below property is causing odd expanding when opening mobile side menu */
  background-size: 100% auto;
}

.outerMiniMap {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-top: -6px;
  margin-left: -6px;
  padding: 5px;
  cursor: pointer;
  -webkit-transition: border-color 0.2s linear;
  -moz-transition: border-color 0.2s linear;
  -ms-transition: border-color 0.2s linear;
  -o-transition: border-color 0.2s linear;
  transition: border-color 0.2s linear;
  white-space: nowrap;
  border: 1px solid #D9D9D9;
  border-radius: 50%;
  background-color: white;
}

.outerMiniMap:hover {
  border-color: #0072CE;
}

.outerHouse {
  overflow: hidden;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.MiniMapHouse {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.mmImg {
  overflow: hidden;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.outer {
  width: 100%;
  height: 100%;
}

.backNav {
  position: absolute;
  margin-top: -37px;
  margin-left: 34px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
}

.showImg {
  transition: opacity 0.5s;
  transition-timing-function: linear;
  opacity: 1;
}

.hideImg {
  opacity: 0;
}
