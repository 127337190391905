@font-face {
    font-family: OpenSans-Bold;
  
    src: url(/fonts/Open_Sans/OpenSans-Bold.ttf);
  }
  
.exploreModalContent {
  text-align: center;
  margin: 0 auto;
}

.exploreTitle {
  font-weight: bold;
  font-size: 20px;
  margin-top: -5px;
  color: #1e3575;
}

.exploreSubTitle {
    font-size: 13px;
    color: #505357;
    padding-top: 10px;
    padding-bottom: 15px;
}

.modalButton {
    display: inline-block;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    background-color: #0072CE;
    width: 220px;
    height: 20px;
    color: white;
}