@font-face {
  font-family: OpenSans-Bold;

  src: url(/fonts/Open_Sans/OpenSans-Bold.ttf);
}

@font-face {
  font-family: OpenSans-ExtraBold;

  src: url(/fonts/Open_Sans/OpenSans-ExtraBold.ttf);
}

@font-face {
  font-family: OpenSans-Light;

  src: url(/fonts/Open_Sans/OpenSans-Light.ttf);
}

.row {
  display: block;
  text-align: center;
}

.myProgress {
  text-align: center;
  color: #1E3575;
  font-size: 20px;
  font-weight: bold;
}

.myProgressMobile {
  padding-bottom: 30px;
  text-align: center;
  color: #1E3575;
  font-size: 20px;
  font-weight: bold;
}

.rowText {
  width: 160px;
  text-align: center;
}

.progressRing {
  display: inline-block;
  overflow: hidden;
  width: 66px;
  height: 140px;
  padding-top: 20px;
  padding-right: 50px;
  padding-bottom: 20px;
  padding-left: 50px;
}

.progressRingMobile {
  display: inline-block;
  overflow: hidden;
  width: 66px;
  height: 140px;
  padding-right: 50px;
  padding-left: 50px;
}

.savingsRing {
  display: inline-block;
  width: 140px;
  height: 100px;
  padding-right: 30px;
  padding-left: 30px;
}

.savingsRingMobile {
  display: inline-block;
  width: 140px;
  height: 100px;
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
}

.titles {
  margin-top: 10px;
  white-space: nowrap;
  letter-spacing: 2px;
  color: #1E3575;
  font-size: 12px;
  font-weight: bold;
}

.overflowCenter {
  margin-right: -100%;
  margin-left: -100%;
  text-align: center;
}

.description {
  width: 130px;
  margin-left: -32px;
  padding-top: 5px;
  font-size: 13px;
}

.inner {
  position: absolute;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 0;
}

.innerCheck {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 0;
}

.savingsTitle {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  color: #1E3575;
  font-size: 16px;
  font-weight: bold;
}

.savingsSubTitle {
  margin-top: -40px;
  letter-spacing: 1.5px;
  color: #1E3575;
  font-size: 12px;
  font-weight: bold;
}

.savingsNumbers {
  color: #0072CE;
  font-family: 'OpenSans-Bold';
  font-size: 30px;
}

.progressHorizontalLine {
  border-top: 1px solid #D9D9D9;
}

.disclosureText {
  padding-top: 20px;
  padding-bottom: 5px;
  letter-spacing: 0;
  color: #505357;
  font-size: 10px;
  line-height: 18px;
}

a {
  text-decoration: none;
  color: #0072CE;
  border-bottom: 1px solid #0072CE;
  font-weight: bold;
}
