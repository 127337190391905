.leftNav {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  overflow-y: scroll;
  height: calc(100vh - 30px);
  padding: 15px;
  color: #7B7B7B;
  background-color: white;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.leftNav::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1008px) {
  .leftNav {
    overflow-y: scroll;
  }
}


.leftNavShaddow {
  box-shadow: 1px 33px 8px -1px rgba(0, 0, 0, 0.08);
}

.leftNavShaddowMap {
  box-shadow: 1px 0 8px -1px rgba(0, 0, 0, 0.08);
}

.collapseIcon {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  width: 60px;
  height: 30px;
  margin-left: -30px;
  cursor: pointer;
  color: #1E3575;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: white;
  box-shadow: 1px 0 8px -1px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 1008px) {
  .collapseIcon {
    padding: 5px;
  }
}

.tablet {
  display: flex;
  align-items: center;
  margin: 10px auto;
  padding: 5px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  -webkit-transition: border 0.2s linear;
  -moz-transition: border 0.2s linear;
  -ms-transition: border 0.2s linear;
  -o-transition: border 0.2s linear;
  transition: border 0.2s linear;
  border: 1px solid #D6D6D6;
  border-radius: 50px;
}

.activeTips {
  border: 1px solid #80D8D3;
  background-color: #F0FFFE;
}

.activeMarketplace {
  border: 1px solid #39AAC8;
  background-color: #DCF1F7;
}

.activeSpecialOffer {
  border: 1px solid #2E9CFF;
  background-color: #E9F2FA;
}

.activeSmartHome {
  border: 1px solid #103DBC;
  background-color: #ECEFFA;
}

.tips:hover {
  border: 1px solid #80D8D3;
}

.marketplace:hover {
  border: 1px solid #39AAC8;
}

.specialOffer:hover {
  border: 1px solid #2E9CFF;
}

.smartHome:hover {
  border: 1px solid #103DBC;
}

.energyTitle {
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 1.5px;
  color: #1E3575;
  font-size: 12px;
  font-weight: bold;
}

.energySubTitle {
  overflow: hidden;
  margin-top: 5px;
  text-align: left;
  white-space: nowrap;
  color: #505357;
  font-size: 13px;
}

.highlightRing {
  overflow: hidden;
  width: 40px;
  height: 40px;
  white-space: nowrap;
}

.displayName {
  overflow: hidden;
  padding-left: 10px;
  white-space: nowrap;
  font-size: 13px;
  font-weight: bold;
}

.navTitle {
  text-align: left;
}

.horizontalLine {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #D9D9D9;
}

.dteTitle {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  color: #1E3575;
  font-family: 'OpenSans-ExtraBold';
  font-size: 20px;
}

.dteSubTitle {
  margin-bottom: 20px;
  color: #1E3575;
  font-size: 20px;
}

.strongInteractive {
  font-family: 'OpenSans-Bold';
}

.progress {
  overflow: hidden;
  margin-top: 15px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  white-space: nowrap;
  color: #0071CE;
  font-family: 'OpenSans-Bold';
  font-size: 14px;
}

.ellipsis {
  margin-left: 6px;
  padding-bottom: 3px;
}

.navSocial {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: #0071CE;
}

.icon {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.miniMap {
  margin: 0 auto;
  margin-top: 40px;
}

.roomName {
  color: #1E3575;
  font-family: 'OpenSans-Bold';
}

.roomArrowNames {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 20px;
}

.arrowButton {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  white-space: nowrap;
  color: #1E3575;
  border: 0.5px solid #D6D6D6;
  border-radius: 50%;
}

.arrow {
  margin-top: 5px;
}

.arrowLeft {
  margin-left: -4px;
}

.arrowRight {
  margin-right: -4px;
}

.arrowIcon {
  margin-left: 37px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
}

.whiteOverlay {
  -webkit-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  -ms-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
  opacity: 0.3;
}

.whiteOverlaySolid {
  opacity: 1;
}
