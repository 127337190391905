.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.ReactModal__Overlay {
  display: flex;
  align-content: center;
  align-items: center;
  transition: opacity 500ms ease-in-out;
  opacity: 0;
}

.ReactModal__Content {
  flex-direction: column;
}

@media only screen and (max-width: 1008px) {
  .ReactModal__Content {
    top: 0 !important;
  }
}


.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
