.threeScene {
  position: absolute;
}

.highlightRoom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  pointer-events: none;
  opacity: 0.0;
}

.highlightActive {
  opacity: 1.0;
}


.highlightSolid {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 275px;
  height: 275px;
  margin: auto;
}

.highlightOuter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 350px;
  height: 350px;
  margin: auto;
  opacity: 0.3;
}

.animationDescription {
  position: absolute;
  right: 0;
  bottom: 20px;
  left: 0;
  width: fit-content;
  margin: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  pointer-events: none;
  opacity: 0.0;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: -2px 0 10px 0 rgba(0,0,0,0.08);
}

@media only screen and (max-width: 1008px) {
  .animationDescription {
    bottom: 220px;
  }
}

.animationDescriptionContainer {
  display: flex;
  justify-content: center;
  width: fit-content;
  min-width: 280px;
  max-width: 340px;
  padding: 11px 37px;
  text-align: center;
  letter-spacing: 0;
  color: #1E3575;
  font-size: 16px;
  line-height: 18px;
}

.animationDescriptionBreak {
  padding-right: 4px;
}

.animationDescriptionContent {
  font-weight: 600;
}

.animationDescriptionActive {
  opacity: 1.0;
}

.seoDescription {
  position: absolute;
  z-index: -2;
  top: 0;
  max-width: 100vw;
  max-height: 100vh;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  pointer-events: none;
}

.animationDescriptionTitle {
  padding-right: 5px;
}
