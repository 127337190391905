.RoomPage {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  color: white;
  background-color: #FFFFFF;
}

.loadingContainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100vh;
  user-select: none;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  opacity: 1;
}

.hideLoading {
  pointer-events: none;
  opacity: 0;
}

.loadBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  background-image: url('img/mapBG.jpg');
  background-position: center;
  background-size: cover;
}

.loadingBarContainer {
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(80% - 230px);
  margin: auto;
  padding-bottom: 5%;
  padding-left: 230px;
  color: #1E3575;
}

.loadingBarContainer a {
  text-decoration: none;
  color: #1E3575;
  border-bottom: 1px solid #1E3575;
  font-weight: bold;
}

.loading404 {
  display: flex;
  align-items: center;
  height: 100%;
}

.loading404 .loadingBarContainer {
  width: 80%;
  padding-left: 0;
}

@media only screen and (max-width: 1008px) {
  .loadingBarContainer {
    width: 80%;
    padding-left: 0;
  }
}

.nowLoading {
  padding-bottom: 10px;
}

.nowLoadingContainer {
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: -0.25px;
  font-size: 20px;
  line-height: 24px;
}

.roomLoading {
  font-weight: bold;
}

.progressback {
  z-index: 1;
  width: 100%;
  border-radius: 7px;
  background-color: #B8D7ED;
}

.progressfront {
  height: 14px;
  border-radius: 10px;
  background-color: #0072CE;
}

.loadDidYouKnow {
  letter-spacing: -0.28px;
  color: #1E3575;
  font-size: 22px;
  font-weight: 600;
}

.loadtips {
  margin-top: 40px;
}

.loadtip {
  max-width: 800px;
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0;
  color: #1E3575;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

@media only screen and (max-width: 1008px) {
  .cardNav {
    top: auto;
    right: 0;
  }
}

.minimap {
  position: absolute;
}

.badge {
  position: absolute;
}
