@font-face {
  font-family: OpenSans;

  src: url(/fonts/Open_Sans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansLight;

  src: url(/fonts/Open_Sans/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansSemiBold;

  src: url(/fonts/Open_Sans/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: OpenSansBold;

  src: url(/fonts/Open_Sans/OpenSans-Bold.ttf);
}

html,
body {
  overflow: -moz-scrollbars-none;
  height: 100%;
  margin: 0;
  background-color: #FFFFFF;
  font-family: OpenSans;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0 !important;
}

.App {
  width: 100%;
  height: 100%;
  text-align: center;
}

button {
  outline: none;
}
