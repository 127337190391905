.video {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 18px;
  padding: 9px 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(30, 53, 117, 0.2);
  border-bottom: 0;
}
  
.videoThumbnail {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-right: 15px;
}

.videoThumbnail {
  max-width: 96px;
  height: auto;
}

.videoThumbnail img{
  max-width: 100%;
  height: auto;
}

.videoTitle {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;

  color: #0072ce;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}

.videoTitle p{
  margin: 0;
}