.videoModalTitle {
    margin-top: -8px; /* Align it with the close X button */
    margin-bottom: 16px;
    padding-left: 10px;
}

.videoModalTitle h2 {
    margin: 0;
    font-size: 18px;
    line-height: 1.5em;
}

.videoModalVideo {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
}

.videoModalVideo iframe {
    width: 100%;
    height: 100%;
}